<template>
  <b-link @click="setMinimize()" v-if="!minimize">
    <b-icon icon="arrows-collapse" title="Minimizar" animation="throb"></b-icon>
  </b-link>
  <b-link @click="setMaximize()" v-else>
    <b-icon icon="arrows-expand" title="Maximizar" animation="throb"></b-icon>
  </b-link>  
</template>

<script>
  export default {
    data() {
      return {
        minimize: false,
      }
    },    
    methods: {      
      setMinimize() {
        document.getElementsByClassName('modal-body')[0].style.display = 'none'
        document.getElementsByClassName('modal-footer')[0].style.display = 'none'  

        this.minimize = true        
      },
      setMaximize() {
        document.getElementsByClassName('modal-body')[0].style.display = 'block'
        document.getElementsByClassName('modal-footer')[0].style.display = 'flex'  

        this.minimize = false
      },
    }
  }
</script>